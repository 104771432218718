import Buttons from './Buttons.json';
import CineDialog from './CineDialog.json';
import Common from './Common.json';
import Header from './Header.json';
import MeasurementTable from './MeasurementTable.json';
import StudyList from './StudyList.json';
import UserPreferencesModal from './UserPreferencesModal.json';

export default { 
  'zh': {
    Buttons,
    CineDialog,
    Common,
    Header,
    MeasurementTable,
    StudyList,
    UserPreferencesModal,
  }
};
